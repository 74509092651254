<template>
    <div class="HomePage"  ref="view">
        <div class="banner">
            <div class="img"><img :src="videoImg" alt=""> </div>
            <div class="play" @click="palyVideo"><img src="../../assets/img/play.png" alt=""> </div>
            <div class="download">
                <div class="qrcode"><img src="@/assets/img/WXGZH.jpg" alt=""> </div>
                <div class="appDown">
                    <a :href="pCios" class="pCios" target="_blank"><div class="ios" @click="downloadIos"><img src="@/assets/img/ios.png" alt=""> App Store 下载</div></a>
                    <a :href="pcAndroid" class="pCios" target="_blank"><div class="android" @click="downloadAddroid"><img src="@/assets/img/android.png" alt=""> Android 下载</div></a> 
                </div>        
            </div>
            <div class="glide" @click="glide">
               <img src="@/assets/img/glide.gif" alt=""> 
            </div>
        </div>
        <div class="video" v-if="videoShow" @click="hideVideo">
            <div class="box"  @click.stop="">
                <video controls autoplay>
                    <source :src="video">
                </video>
                <div class="close" @click="closePlay"><img src="../../assets/img/closePlay.png" alt=""></div>
            </div>
        </div>
        <div class="afficheNews">
            <div class="header">
                <div class="news">
                    <div class="line"><span></span><img src="@/assets/img/line.png" alt=""> </div>
                    <div class="txt">公告新闻</div>
                    <div class="line1"><img src="@/assets/img/line.png" alt=""><span></span></div>
                </div>
            </div>
            <div class="content">
                <div class="detail">
                    <div class="banner">
                        <div style="height: 420px;" :class="{'liangztupian':stl&&bannerData.length==2,'yizhangtupian':!stl&&bannerData.length==2}">
                            <el-carousel v-if="bannerData.length>1"  :interval="5000" height="420px"  @change="carouselChange">
                                <el-carousel-item v-for="item in bannerData" :key="item.id" @click.native="banerChange(item)">
                                    <span class="title">{{ item.title }}</span>
                                    <img :src="item.banner" alt="">
                                    <div class="bottom"></div>
                                </el-carousel-item>

                                <div v-if="bannerData.length==2"  >
                                    <el-carousel-item v-for="item in bannerData" :key="item.id" @click.native="banerChange(item)">
                                        <span class="title">{{ item.title }}</span>
                                        <img :src="item.banner" alt="">
                                        <div class="bottom"></div>
                                    </el-carousel-item>
                                </div>
                            </el-carousel>
                            <div v-if="bannerData.length==1" class="bannerImg" @click="bannerImgChange()">
                                <span class="title">{{ bannerData[0].title }}</span>
                                <img :src="bannerData[0].banner" alt="">
                                <div class="bottom"></div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="tab">
                        <div class="tabHeader">
                            <div class="list"  :id=" 'list' + index " @click="tabChange(index)" v-for="(item,index) in tabList" :key="index" :class="{active:currentType==index}">{{ item.name }}</div>
                        </div>
                        <div class="default" v-if="newsList.length<1">
                            <img src="../../assets//img/default.png" alt="">
                            <div style="margin-top: 20px;">暂无内容</div>
                        </div>
                        <div  class="tabContent" v-for="(item,index) in tabList" :key="index" v-show="currentType == index">
                            <div class="tabList" v-for="(Iatem,index) in newsList" :key="index" @click="newsChange(Iatem)">
                                <span class="title" v-if="Iatem.dataType == 1">公告</span>
                                <span class="title" v-if="Iatem.dataType == 2">新闻</span>
                                <span class="title" v-if="Iatem.dataType == 3">活动</span>
                                <div class="txt">{{ Iatem.title }}</div>
                                <span class="time">{{ Iatem.releaseTime | moment }}</span>
                            </div>
                        </div>
                        <div class="more" v-if="newsList.length>4" @click="moreDetail">查看更多 </div>
                    </div>
                </div>
            </div>
            <div class="bottom header">
                <div class="news">
                    <div class="line"><span></span><img src="@/assets/img/line.png" alt=""> </div>
                    <div class="txt">世界入口</div>
                    <div class="line1"><img src="@/assets/img/line.png" alt=""><span></span></div>
                </div>
            </div>
        </div>
        <div class="city">
            <div class="city_list">
                <div class="city_list_item" @click="cityTab(index)" v-for="(item,index) in cityList" :key="index">
                    <img :src="item.image" alt="">
                    <p></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
 import moment from "moment";
export default {
    name: 'HomePage',
    data() {
        return {
            currentType:0,
            videoShow:false,
            videoImg:"",
            video:"",
            pCios:"",
            pcAndroid:"",
            page:1,
            count:5,
            dataType:'',
            bannerData:[
                
            ],
            newsList:[],
            tabList:[
                {
                    name:"最新"
                },
                {
                    name:"活动",
                },
                {
                    name:"公告",
                },
                {
                    name:"新闻",
                },
                
            ],
            cityList:[
                {
                    image:require('@/assets/img/ziyou.png'),
                    name:"自由邦"
                },
                {
                    image:require('@/assets/img/weilai.png'),
                    name:"未来国"
                },
                {
                    image:require('@/assets/img/shiguang.png'),
                    name:"时光岛"
                },
                {
                    image:require('@/assets/img/dushi.png'),
                    name:"都市圈"
                },
                {
                    image:require('@/assets/img/miwu.png'),
                    name:"迷雾海域"
                },
                

            ],
            stl:true
        }
    },
    components: {

    },
    mounted(){
        this.getData()
        this.getNewList();
    },
    created() {
    //   this.go()
        setTimeout(() => {
            this.go()
        }, 100);
    },
    updated(){
        // this.go()
    },
    methods:{
        carouselChange(data){

            if(data==0||data==1){

            this.stl=true

            }else{

            this.stl=false

            }

        },
        go(){
            let box = document.querySelector('.container')
            box.scroll(0,0)
        },
        glide(){
            let box = document.querySelector('.container')
            box.scroll(0,900)
        },
        async getData(){
            const res = await this.$https.getFirstScreenVideo();
            if(res.success == true){
                let data = res.data;
                this.videoImg = data.image;
                this.video = data.video;
            }else{
                this.$message({
                  message:res.errMessage,
                  type: "warning",
                });
            }

            let data = {
                type:0,
            }
            const formData = new FormData();
            Object.keys(data).forEach(key => {
                formData.append(key, data[key]);
            });
            const res1 =  await this.$https.getDownloadLink(formData);
            if(res1.success == true){
                // console.log(res1)
                let data = res1.data;
                this.pCios = data.ios;
                this.pcAndroid = data.android;
            }else{
                this.$message({
                  message:res1.errMessage,
                  type: "warning",
                });
            }

            const res2 = await this.$https.newsNoticeBannerInformation();
            if(res2.success == true){
                // console.log(res2.data)
                this.bannerData = res2.data;
            }else{
                this.$message({
                  message:res2.errMessage,
                  type: "warning",
                });
            }
        },
        async getNewList(){
            let data1 = {
                page:this.page,
                count:this.count,
                dataType:this.dataType,
            }
            const formData1 = new FormData();
            Object.keys(data1).forEach(key => {
                formData1.append(key, data1[key]);
            });
            const res3 = await this.$https.newsNoticeInformation(data1)
            if(res3.success == true){
                let data = res3.data.newsNoticeDataCOS;
                this.newsList = data;
            }else{
                this.$message({
                  message:res3.errMessage,
                  type: "warning",
                });
            }
        },
        downloadAddroid(){
            // let routerURL = this.$router.resolve({
            //     path:this.pcAndroid,
            // });
            // window.open(routerURL.href,'_blank')
        },
        downloadIos(){
           
        },
        tabChange(index){
            console.log(this.newsList)
            this.currentType = index;
            if(this.currentType == 0){
                this.dataType = '';
            }else if(this.currentType == 1){
                this.dataType = 3;
            }else if(this.currentType==2){
                this.dataType = 1;
            }else if(this.currentType == 3){
                this.dataType = 2;
            }
            this.getNewList()
        },
        cityTab(index){
            // if(index == 0){
                this.$router.push({
                    path: "/HomeView/WorldView",
                    query: {
                        name:index
                    }
                });
            // }
        },
        moreDetail(){
            this.$router.push({
                path:"/HomeView/NewsView"
            })
        },
        banerChange(item){
            if(item.textLink == "" && item.url != '' ){
                window.open(item.url,'_blank')
            }
            if(item.textLink != '' && item.url != ''){
                this.$router.push({
                    path:"/HomeView/newsDetail",
                    query: {
                        name:item
                    }
                })
            }
        },
        bannerImgChange(){
            if(this.bannerData[0].textLink == "" && this.bannerData[0].url != '' ){
                window.open(this.bannerData[0].url,'_blank')
            }
            if(this.bannerData[0].textLink != '' && this.bannerData[0].url != ''){
                this.$router.push({
                    path:"/HomeView/newsDetail",
                    query: {
                        name:this.bannerData[0]
                    }
                })
            }
        },
        newsChange(Iatem){
            if(Iatem.textLink == "" && Iatem.url != '' ){
                window.open(Iatem.url,'_blank')
            }
            if(Iatem.textLink != '' && Iatem.url != ''){
                this.$router.push({
                    path:"/HomeView/newsDetail",
                    query: {
                        name:Iatem
                    }
                })
            }
            // this.$router.push({
            //     path:"/HomeView/newsDetail",
            //     query: {
            //         name:Iatem
            //     }
            // })
        },
        palyVideo(){
            this.videoShow = true;
        },
        hideVideo(){
            this.videoShow = false;
        },
        closePlay(){
            this.videoShow = false;
        },
        dateFormat: function(row, column) {
            //时间戳转化日期格式 moment
            var date = row[column.property];
            if (date == undefined) {
            return "";
            }
            return moment(date).format("YYYY-MM-DD");
        },
    },
    filters:{
      moment(e) {
        return moment(e).format("YYYY-MM-DD");
      },
    },
}
</script>
<style scoped lang="less">
.HomePage{
    // height: 100%;
    background: linear-gradient(0deg, #090522, #1E183D);
}
.banner{
    width: 100%;
    height: 100vh;
    position: relative;
    .el-carousel{
        width: 100% !important;
        height: 100% !important;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .img{
        width: 100%;
        height: 100vh;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .play{
        width: 80px;
        height: 80px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        cursor: pointer;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .download{
        position: absolute;
        bottom: 100px;
        left: 50%;
        transform: translate(-50%);
        width: 380px;
        height: 160px;
        // background: url(@/assets/img/down.png) no-repeat;
        // background-size: 100% 100%;
        background: rgba(255, 255, 255, 0.29);
        // box-shadow: 0px 0px 6px 0px rgba(255,255,255,0.5), 0px 0px 249px 0px rgba(255,255,255,0.29);
        border-radius: 20px;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content:space-evenly;
        .qrcode{
            width: 130px;
            height: 130px;
            img{
                width: 100%;
                height: 100%;
                border-radius: 10px;
            }
        }
        .appDown{
            width: 200px;
            height: 130px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            .ios{
                width: 190px;
                height: 55px;
                background: #272636;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #FFFFFF;
                cursor: pointer;
                img{
                    width: 20px;
                    height: 26px;
                    margin-right: 10px;
                }
            }
            .android{
                width: 190px;
                height: 55px;
                background: #A4CA39;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #FFFFFF;
                cursor: pointer;
                img{
                    width: 20px;
                    height: 22px;
                    margin-right: 10px;
                }
            }
        }
    }
    .glide{
        width: 60px;
        height: 60px;
        position: absolute;
        left: 50%;
        bottom: 20px;
        transform: translate(-50,0);
        cursor: pointer;
        img{
            width: 100%;
            height: 100%;
        }        
    }
}
.banner /deep/ .el-carousel__container{
    width: 100% !important;
    height: 100% !important;
}
.video{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index:99;
    background: RGBA(0, 0, 0, 0.5);
    .box{
        width: 1100px;
        height: 550px;
        position: relative;
        video{
            width: 896px;
            height: 504px;
            // object-fit: cover;
            source{
                width: 100%;
                height: 100%;
                
            }
        }
        .close{
            width: 50px;
            height: 50px;
            position: absolute;
            top: -10px;
            right: 0;
            cursor: pointer;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    
    
}
.afficheNews{
    width: 100%;
    height: 74vh;
    // background: linear-gradient(0deg, rgba(9, 5, 34,0.9), rgba(30, 24, 61,0.9));
    background: url(../../assets/img/afficheNews.png) no-repeat;
    background-size: 100% 100%;
    padding-top: 50px;
    .header{
        width: 100%;
        height: 15%;
        // background: #ccc;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: center;
        .news{
            width: 40%;
            height: 100%;
            display: flex;
            align-items: center;
            // background: #c33;
            justify-content: center;
            .line{
                width: 220px;
                height: 10px;
                display: flex;
                align-items: center;
                justify-content: space-around;
                margin-right: 10px;
                span{
                    width: 200px;
                    height: 1px;
                    background: url(@/assets/img/left.png) no-repeat;
                    background-size: 100% 100%;
                }
                img{
                    width: 10px;
                    height: 8px;
                }
            }
            .line1{
                width: 220px;
                height: 10px;
                display: flex;
                align-items: center;
                justify-content: space-around;
                margin-left: 10px;
                span{
                    width: 200px;
                    height: 1px;
                    background: url(@/assets/img/right.png) no-repeat;
                    background-size: 100% 100%;
                }
                img{
                    width: 10px;
                    height: 8px;
                }
            }
            .txt{
                font-size: 30px;
                // font-weight: bold;
                color: #FFFFFF;
                letter-spacing: 3px;
            }
        }
    }
    .content{
        width: 100%;
        height: 50%;
        // background: url(@/assets/img/newsBj.png) no-repeat;
        // background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        .detail{
            width: 1249px;
            height: 360px;
            background: rgba(29, 23, 60, 0.6);
            // border-radius: 10px;
            display: flex;
            .banner{
                width: 50%;
                height: 100%;
                img{
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                }
                /deep/.el-carousel__arrow--left{
                    background:url(../../assets/img//word//arrowL.png) no-repeat !important;
                    background-size: 100% 100% !important;
                }
                /deep/.el-carousel__arrow--right{
                    background:url(../../assets/img//word//arrowR.png) no-repeat !important;
                    background-size: 100% 100% !important;
                }
                .title{
                    display: inline-block;
                    width: 101%;
                    height: 56px;
                    line-height: 56px;
                    background: url(@/assets/img/title.png) no-repeat;
                    background-size: 100% 100%;
                    // border-radius: 10px 0px 0px 0px;
                    position: absolute;
                    top:0;
                    left: 0;
                    color: #fff;
                    font-size: 22px;

                }
                .bottom{
                    width: 100%;
                    height: 27px;
                    position: absolute;
                    bottom:0;
                    left: 0;
                    bottom:0;
                    background: url(@/assets/img/bottom.png) no-repeat;
                    background-size: 100% 100%;
                }
                .bannerImg{
                    width: 100%;
                    height: 420px;
                    position: relative;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .tab{
                width: 50%;
                height: 100%;
                position: relative;
                .tabHeader{
                    width: 95%;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    
                    color: #fff;
                    border-bottom: 1px solid #CACEDE;
                    position: relative;
                    margin: auto;
                    .list{
                        width: 50px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        position: relative;
                        cursor: pointer;
                        // margin-left: 40px;
                        font-size: 18px;
                    }
                    #list1,#list2,#list3{
                        margin-left: 40px;
                    }
                }
                .more{
                    width: 136px;
                    height: 30px;
                    line-height: 30px;
                    color: #E6E8F4;
                    position: absolute;
                    right: 10px;
                    bottom: 10px;
                    cursor: pointer;
                    background:url(../../assets/img/add.png) no-repeat;
                    background-size: 100% 100%;
                }
                .default{
                    width: 100%;
                    height: calc(100% - 40px);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    letter-spacing: 2px;
                    img{
                        width: 240px;
                        height: 100px;
                    }
                }
                .tabContent{
                    width: 100%;
                    height: calc(100% - 40px);
                    margin-top: 10px;
                    .tabList{
                        width: 100%;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        color: #FFFFFF;
                        justify-content: space-between;
                        cursor: pointer;
                        .title{
                            width: 44px;
                            height: 18px;
                            line-height: 18px;
                            display: inline-block;
                            border: 1px solid #fff;
                            border-radius: 4px;
                            margin-left: 15px;
                            font-size: 14px;
                        }
                        .txt{
                            width: 470px;
                            text-align: left;
                            margin-left: 10px;
                            overflow:hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            -o-text-overflow:ellipsis;
                            letter-spacing: 2px;
                            font-size: 20px;
                        }
                        .time{
                            width: 150px;
                            font-weight: 400;
                            color: #9EA0AA;
                            margin-right: 15px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .bottom{
        width: 100%;
        height: 15%;
        // background: #2D84D1;
        margin-top: 80px;
    }
}
.city{
    width: 100%;
    .city_list{
        .city_list_item{
            width: 100%;
            height: 300px;
            position: relative;
            cursor: pointer;
            img{
                width: 100%;
                height: 100%;
            }
            p{
                width: 210px;
                height: 10px;
                // position: absolute;
                // bottom: 91px;
                // left: 50%;
                // transform: translateX(-50%);
            }
        }
    }
}
.city_list_item::before{
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(6, 11, 39, 0.3);
}
.city_list_item:hover::after{
    position: absolute;
    bottom: 95px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    content: '';
    width: 195px;
    height: 10px;
    background: url(@/assets/img/active.png);
    background-size: 100% 100%;  
    // opacity: 0;
}
.city_list_item:hover{
    border: 5px solid #fff;
}
.city_list_item:hover:before{
    display: none;
}
.active{
    color: #5A9AFF;
}
.active::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 3px;
    background: #528CE8;
    border-radius: 5px;
}
.pCios{
    text-decoration: none;
}
.afficheNews /deep/.el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px !important;
  height: 10px !important;
  background: transparent !important;
  border: 0px solid #ffffff !important;
  border-radius: 50% !important;
  /* opacity: 0.5; */
  background-color: rgba(214, 214, 214, 1) !important;
}

.afficheNews /deep/.el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 10px !important;
  height: 10px !important;
  background: RGBA(17, 104, 253, 1) !important;
  border-radius: 50% !important;
  opacity: 1 !important;
}
.afficheNews /deep/.el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px !important;
  height: 10px !important;
  background: transparent !important;
  border: 0px solid #ffffff !important;
  border-radius: 50% !important;
  /* opacity: 0.5; */
  background-color: rgba(214, 214, 214, 1) !important;
}

.afficheNews /deep/.el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 10px !important;
  height: 10px !important;
  background: RGBA(17, 104, 253, 1) !important;
  border-radius: 50% !important;
  opacity: 1 !important;
}

.yizhangtupian{

/deep/ .el-carousel__indicators{

      &>li:nth-child(1),&>li:nth-child(2){

         display: none;

      }

  }

}
.liangztupian{

/deep/ .el-carousel__indicators{

    &>li:nth-child(3),&>li:nth-child(4){

       display: none;

    }

}

}
</style>