<template>
  <div id="app">
    <router-view/>    
  </div>
</template>
<script>
// @ is an alias to /src
export default {
  components: {
  },
  mounted() {
    // let myCenter =  localStorage.getItem('myCenter')
    // if (this._isMobile()) {
    //   this.$router.push("/Mobile/MobileHomePage");
    // }else if(myCenter != '' && myCenter != null){
    //   this.$router.push("/myCenter")
    // }
    //  else {
    //   this.$router.push("/");
    // }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  }
}
</script>
<style lang="less">
@import url(./assets/css/TiYuan.css);
html,body {
  height: 100%;
}
#app {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-family: YouSheBiaoTiYuan;
  -webkit-touch-callout:none; /*系统默认菜单被禁用*/
 -webkit-user-select:none; /*webkit浏览器*/
 -khtml-user-select:none; /*早期浏览器*/
 -moz-user-select:none;/*火狐*/
 -ms-user-select:none; /*IE10*/
 user-select:none; 
}
</style>
