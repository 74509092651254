<template>
    <div></div>
</template>
<script>
export default{
    data(){
        return{
            
        }
    },
    mounted() {
        if (this._isMobile()) {
            this.$router.replace("/Mobile/MobileHomePage");
        }
        else {
            this.$router.replace("/HomeView/HomePage");
        }
    },
    methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  }
}
</script>
<style>

</style>