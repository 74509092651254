<template>
  <div class="container">
    <div class="header">
      <div class="logo"><img src="../assets/img/logo.png" alt="" @click="logoReload"> <span>眼艺</span></div>
      <nav>
        <router-link to="/HomeView/HomePage" active-class="active" tag="span" replace>首页</router-link>
        <router-link to="/HomeView/WorldView" active-class="active" tag="span" replace>世界入口</router-link>
        <router-link to="/HomeView/Feature" active-class="active" tag="span" replace>特色玩法</router-link>
        <router-link to="/HomeView/NewsView" active-class="active" tag="span" replace>公告新闻</router-link>
        <!-- <router-link to="/HomeView/JobberView" active-class="active" tag="span" replace>交易行</router-link> -->
        <router-link to="/HomeView/OurselvesView" active-class="active" tag="span" replace>关于我们</router-link>
      </nav>
      <div class="loginRegister">
        <!-- <div class="user" v-if="!alreadyLogIn"><img src="@/assets/img/cnetr.png" alt=""></div> -->
        <div class="user1" v-if="alreadyLogIn"><img :src="headPortrait" alt=""></div>
        <span @click="loginChange" v-if="!alreadyLogIn" class="welcome">欢迎登录</span>
        <span @click="enter" v-else style="color:#fff">{{ phone }}</span>
        <div class="btm" v-if="alreadyLogIn" @click="enter"><img src="@/assets/img/mobile/bottom.png" alt=""> </div>
      </div>
    </div>
    <router-view/>
    <div class="bottom">
      <div class="footer-link">
        <span @click="userAgreement">用户协议</span> 
        |
        <span @click="Privacy()">隐私政策</span> 
        |
        <span @click="AboutUs">关于我们</span> 
      </div>
      <div class="inline-info">
        <span>开发公司：北京艺搭科技有限公司</span>
        &nbsp;|&nbsp;
        <span>联系邮箱：yida@yicreator.com</span>
      </div>
      <div class="inline-info">
        <a href="http://beian.miit.gov.cn?spm=a213l2.13146415.0.0.36b771e1CQZ57r" target="_blank"><img src="../assets/img/jbah.gif" alt=""> 京ICP备2022033211号-1</a>
        &nbsp;|&nbsp;
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010602104732" target="_blank"><img src="../assets/img/beiantubiao.png" alt=""> 京公网安备 11010602104732号</a>
        <!-- | -->
        <!-- <span>文网文号:</span> -->
        &nbsp;|&nbsp;
        <!-- <span>APP软著号：A软著登字第A0000044号</span> -->
        <!-- | -->
        <!-- <span>出版物经营许可证:</span> -->
        <!-- | -->
        <span>APP软著号：A软著登字第A0000044号</span>
      </div>
    </div>
    <div v-if="logInShow" class="login" @click="hideLogInSho">
      <div class="logInContent" @click.stop="">
        <!-- 登录 -->
        <div class="enroll" v-if="!registerShow">
          <div class="logo"><img src="@/assets/img/login.png" alt=""> </div>
          <div class="close" @click="CloseEvent"><img src="@/assets/img/close.png" alt=""> </div>
          <div class="title">手机号登录
            <!-- <span class="line"></span> -->
          </div>
          <div class="content">
            <div class="login_num">
              <section class="num">
                  <el-input v-model="loginAccount" maxlength="11" placeholder="请输入手机号"></el-input>
              </section>
            </div>
            <div class="login-verifyCode">
              <section class="loginCode">
                  <el-input v-model="verifyCode" maxlength="6" placeholder="请输入验证码">
                  <el-button slot="append" v-if="codeShow" @click="getCode" >获取验证码</el-button>
                    <template slot="append" v-else>{{count}}s</template>
                  </el-input>
              </section>
            </div>
            <div  class="onlogin">
              <div class="onlogin-text" @click="loginClick"> 登录</div>
            </div>
            <div class="register-link">
                <span @click="registerChange">注册账号</span>
            </div>
          </div>
          <div class="Privacy">
            <div class="text">未注册的手机号验证后将自动登录。注册/登录即代表您同意并遵守<span @click="userAgreement">《眼艺用户协议》</span>与<span @click="Privacy()">《眼艺隐私政策》</span></div>
          </div>
        </div>
        <!-- 注册 -->
        <div class="register" v-if="registerShow">
          <div class="logo"><img src="@/assets/img/login.png" alt=""> </div>
          <div class="close" @click="CloseEvent"><img src="@/assets/img/close.png" alt=""> </div>
          <div class="title">注册眼艺账号
            <!-- <span class="line"></span> -->
          </div>
          <div class="content">
            <div class="login_num">
              <section class="num">
                  <el-input v-model="loginAccount" maxlength="11" placeholder="手机号注册登录"></el-input>
              </section>
            </div>
            <div class="login-verifyCode">
              <section class="loginCode">
                  <el-input v-model="verifyCode" maxlength="6" placeholder="请输入验证码">
                  <el-button slot="append" v-if="codeShow" @click="getCode" >获取验证码</el-button>
                    <template slot="append" v-else>{{count}}s</template>
                  </el-input>
              </section>
            </div>
            <div  class="onlogin" @click="loginClick">
              <div class="onlogin-text">注册</div>
            </div>
            <div class="register-link">
                <span @click="enrollChange">返回登录</span>
            </div>
          </div>
          <div class="Privacy">
            <el-checkbox v-model="checked" style="margin-right: 5px;"></el-checkbox>
            <div class="text">已阅读并同意<span @click="userAgreement">《眼艺用户协议》</span>与<span @click="Privacy()">《眼艺隐私政策》</span></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 滑动图片验证 -->
    <div class="SlideVerify" v-show="isShowSlide">
          <div class="content-box">
              <div class="header1">
                  <div class="name">请完成安全验证</div>
                  <div class="close" @click="closeSlide"><img src="@/assets/img/close.png" alt=""> </div>
              </div>
              <div class="verify-box">
                  <slide-verify ref="slideRef" :l="42" :r="10"
                  :w="240" :h="150" :imgs="images" slider-text="向右滑动完成验证"
                  @success="onSuccess" @fail="onFail" @refresh="onRefresh"
                  @fulfilled="onRefreshComplete" @again="onAgain">
                  </slide-verify>
              </div>
              <div class="tips" :class="isSuccess ? '' : 'red'">{{tipsMsg}}</div>
          </div>
      </div>
    <!-- 点击账号 -->
    <div class="myCenter" v-show="myCenter">
      <div class="center" @click="Personal"><img src="@/assets/img/cnetr.png" alt=""> <span>个人中心</span></div>
      <div class="logout" @click="logOut"><img src="@/assets/img/logout.png" alt=""> <span>退出登录</span> </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'HomeView',
  data(){
    return{
      phone:"",
      alreadyLogIn:false,//是否登录
      loginAccount:"",//手机号
      verifyCode:"",//验证码
      codeShow:true,//获取验证码
      headPortrait:"",//头像
      logInShow:false,//登录
      registerShow:false,
      count:60,
      checked:false,//是否勾选用户协议
      // 图片验证
      isShowSlide:false,
      images: [
        require('../assets/img/1.png'),
        require('../assets/img/5.png'),
        require('../assets/img/8.png')
      ],
      tipsMsg:'',
      isSuccess: true,
      isCount:'1',
      myCenter:false,//个人中心下拉框
    }
  },
  components: {
    
  },
  mounted(){
    this.initialize();
    const state = { name: "/" }

    history.pushState(state, null, "/");
  },

  methods:{
    closeSlide(){
          this.isShowSlide = false;
      },
    //判断当前是否登录
    initialize(){
        var token=localStorage.getItem("token")
      
        if (token) {
          // 状态改为一登陆
          this.alreadyLogIn=true
          //头像
          this.headPortrait=localStorage.getItem("image")
          this.phone=localStorage.getItem("phone")
        }else{
          this.alreadyLogIn=false
        }
    },
    loginChange(){
      this.logInShow = true;
    },
    hideLogInSho(){
      this.logInShow = false
    },
    CloseEvent(){
      this.logInShow = false
    },
    logOut(){
      this.myCenter = false;
      this.logoutArgument()
    },
    async logoutArgument(){
      let token = localStorage.getItem("token");
      const res = await this.$https.websiteLoginOut();
      console.log(res)
      if (res.success == true) {
        this.alreadyLogIn=false;
        this.phone = "";
        this.headPortrait=""
        localStorage.removeItem('token');
        localStorage.removeItem('image');
        localStorage.removeItem('phone')
      }else if (res.errCode == "408") {
        this.$message({
          showClose: true,
          message: "请重新登录",
          // type: "warning"
        });
        //标识为退出登录
        this.alreadyLogIn=false;
        //标识名字为空
        this.phone="";
        this.headPortrait="";
        localStorage.removeItem('token');
        localStorage.removeItem('image');
        localStorage.removeItem('phone')
      }  else if (res.errCode == "500") {
        this.$message({
          showClose: true,
          message: res.errMessage,
          // type: "warning"
        });
      }else if(res.errCode == "409"){
        this.$message({
          showClose: true,
          message: "请重新登录",
          // type: "warning"
        });
        //标识为退出登录
        this.alreadyLogIn=false
        //标识名字为空
        this.phone=""
        this.headPortrait="";
        localStorage.removeItem('token');
        localStorage.removeItem('image');
        localStorage.removeItem('phone')
      }
    },
    getCode(){
      if (this.loginAccount == "") {
        this.$message({
          showClose: true,
          message: "手机号码为空",
          // type: "warning",
        });
        return false;
      } else if (!/^1[3456789]\d{9}$/.test(this.loginAccount)) {
        this.$message({
          showClose: true,
          message: "请填写正确手机号",
          // type: "warning",
        });

        return false;
      } else {
        this.isShowSlide = true;
        // if(this.isCount == '2'){
        //   this.codeShow = false;
        //   const TIME_COUNT = 60;
        //   // this.phoneArgument();
        //   if (!this.timer) {
        //     this.count = TIME_COUNT;
        //     this.codeShow = false;
        //     this.timer = setInterval(() => {
        //       if (this.count > 0 && this.count <= TIME_COUNT) {
        //         this.count=this.count-1;
        
        //       } else {
        //         this.codeShow = true;
        //         clearInterval(this.timer);
        //         this.timer = null;
        //       }
        //     }, 1000);
        //   }
        // }
        
      }
    },
       //   验证码获取
    async phoneArgument() {
      const res = await this.$https.getWebsiteCode({
        phone: this.loginAccount,
      });
      console.log(res)
      var cotionCode = res.errCode;
      if (cotionCode == null) {
        this.$message({
          showClose: true,
          message: "获取验证码成功！",
          // type: "success",
        });
      } else if (cotionCode == 401) {
        this.$message({
          showClose: true,
          message: "请填写正确手机号",
          // type: "warning",
        });

        return false;
      } else {
        this.$message({
          showClose: true,
          message: res.errMessage,
          // type: "warning",
        });
        return false;
      }
    },
    loginClick(){
      var _this = this;
      //  localStorage.setItem('token','eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiIwYzhhODdkMy1mNjE4LTQxMmItYmE4OC0wOWExMjIyMyIsIm5pY2tOYW1lIjoi5r2Y56eL5aicIiwic2NvcGUiOlsidGVzdCJdLCJpZCI6IjBjOGE4N2QzLWY2MTgtNDEyYi1iYTg4LTA5YTEyMjIzIiwiZXhwIjoxNTczMTkyMzg5LCJhdXRob3JpdGllcyI6IlJPTEVfMTU2OTY1MzA5MTg4OSxST0xFX0FVVEhPUklUWV9NQU5BR0VfUEFHRSxST0xFX0FVVEhPUklUWV9NT0RVTEVfVklFVyxST0xFX0RFQUxfUk9MRSxST0xFX0RFQUxfTUVNQkVSLFJPTEVfMTU2OTY1MzA5MTg4OSxST0xFX01BTkFHRV9VU0VSX1BBR0UsUk9MRV9VU0VSX01PRFVMRV9WSUVXIiwianRpIjoiNDA4OGY4MDMtYmQ5Zi00ODM0LTgxNmItM2RmODA2YzA1ZTE2IiwiY2xpZW50X2lkIjoiY2xpZW50MSJ9.P-lJTTrTv5wjX52adSAynYCZxuPbCUIPTtMHtU8xWv8ZLmJTkM03wMgm_hmL7xZHRolM1RAXvLkAUL7GXDhP8Twt2bMScTi1b3DVd8d7Z5zjH_2k4IxKGGY1IyidTHaEn1FImM3XRrAqixFT-leAFWjoRSP1WvUgZ-qGs1Vio5D-vD-a5rPBfMshGBgYqSKtrMvBtpHZ_Aut_KfNDepdt-FNYJvhGoyoAfpe6T0J02reCdnGR9jxtGLZywEFzvJd8qf0yp7jsFZ7vVZQs_fMiH7p_AKd85suVAz_mAt5aqUPV16Z-cVud323J4ue03y-8TOQHkyNdVjHB5BW-qcGYw')
      if (!/^1[3456789]\d{9}$/.test(this.loginAccount)) {
        this.colors = { borderColor: "#ebeef3" };
        this.$message({
          showClose: true,
          message: "请填写正确手机号",
          // type: "warning",
        });

        return false;
      } else if (this.loginAccount == "") {
        this.$message({
          showClose: true,
          message: "请填写手机号",
          // type: "warning",
        });
        return false;
      } else if (this.verifyCode == "") {
        this.$message({
          showClose: true,
          message: "请填写验证码",
          // type: "warning",
        });
        return false;
      } else if (!/^1[3456789]\d{9}$/.test(this.loginAccount)) {
        this.$message({
          showClose: true,
          message: "请填写手机号",
          // type: "warning",
        });
        return false;
      }
       else if ( this.registerShow == true && this.checked == false) {
        this.$message({
          showClose: true,
          message: "请勾选用户协议和隐私协议",
          // type: "warning",
        });
        return false;
      } 
      else {
        this.loginArgument();
      }
    },
    registerChange(){
      this.registerShow = true;
    },
    enrollChange(){
      this.registerShow = false;
    },
    enter(){
      this.myCenter = !this.myCenter;
    },
    Personal(){
      let routerURL = this.$router.resolve({
        path:"/myCenter",
      });
      window.open(routerURL.href,'_blank')
    },
    //登录接口
    async loginArgument() {
      var _this = this;
      const res = await this.$https.phoneLogin({
        phone: this.loginAccount,
        code: this.verifyCode,
        deviceno:'web',
        userStatus:'web',
      });
      console.log(res)
      var code = res.errCode;
      var outCode = res.success;
      if (outCode == true) {
        var gainTokoen = res.data.access_token; //获取token
        localStorage.setItem("token", gainTokoen);
        var loginAccount = res.data.phone;
        localStorage.setItem("phone", loginAccount);
        var image = res.data.webHead;
        this.headPortrait= image;
        localStorage.setItem("image", image)
        this.loginAccount = "";
        this.count = 60;
        this.verifyCode = '';
        this.registerShow = false;
        if (code === null) {
          this.$message({
            showClose: true,
            message: "登录成功",
            // type: "success",
          });
          if (res.success == true) {
            this.logInShow=false
            this.alreadyLogIn=true;
            this.phone = res.data.phone;
          } else if (code === "410") {
            this.$message({
              showClose: true,
              message: res.errMessage,
              // type: "warning",
            });
          }
        }
      } else if (outCode == false) {
          this.$message({
            showClose: true,
            message: res.errMessage,
            type: "warning",
          });
      }
    },
    userAgreement(){
      this.$router.push({path:'/HomeView/UserAgreement'})
      this.logInShow = false;
    },
    Privacy(){
      this.$router.push({path:'/HomeView/PrivacyPolicy'})
      this.logInShow = false;
    },
    // 成功回调
    onSuccess(){
        this.tipsMsg = '恭喜您，验证成功啦！';
        this.isSuccess = true;
        this.isShowSlide = false;
        this.isCount = '2';
        if(this.isCount == '2'){
          this.codeShow = false;
          const TIME_COUNT = 60;
          this.phoneArgument();
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.codeShow = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count=this.count-1;
        
              } else {
                this.codeShow = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        }
    },
    logoReload(){
      location.reload();
      // this.$router.go(0)
    },
    AboutUs(){
      this.$router.push({path:'/HomeView/OurselvesView'})
    },
    // 失败回调
    onFail(){
        this.tipsMsg = '验证失败，请重试哦！';
        this.isSuccess = false;
    },
    // 点击刷新回调
    onRefresh(){
        this.tipsMsg = ''
    },
    // 刷新成功后回调
    onRefreshComplete(){
      // TODO
      this.tipsMsg = ''
      this.isSuccess = true;
    },
    // 检测到非人为操作
    onAgain() {
      this.tipsMsg = "再试一次吧～"
      this.$refs.slideRef.reset();
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  background: #000;
}
.header{
  width: 100vw;
  height: 60px;
  display: flex;
  align-items: center;
  color: #fff;
  background: linear-gradient(0deg, rgba(9, 5, 34,0.9), rgba(30, 24, 61,0.9));
  z-index: 999;
  position: fixed;
  top: 0;
  .logo{
    width: 200px;
    height: 60;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 38px;
      height: 33px;
      cursor: pointer;
    }
    span{
      margin-left: 10px;
    }
  }
  nav {
    width: 600px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    span{
      display: inline-block;
      width: 80px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      cursor: pointer;
      position: relative;
      letter-spacing: 2px;
    }
    .active{
      text-decoration: none;
      text-shadow: 0 0 7px #528CE8;
    }
    .active::after{
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%,0);
      width: 30px;
      height: 3px;
      background: #528CE8;
      border-radius: 5px;
    }
  }
  .loginRegister{
    width: 200px;
    height: 60px;
    display: flex;
    align-items: center;
    // justify-content: space-evenly;
    position: absolute;
    right: 50px;
    .user{
      width: 30px;
      height: 30px;
      background: #1F1936;
      border: 1px solid #9EA0AA;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 30px;
      img{
        width: 13px;
        height: 15px;
        // border-radius: 50%;
      }
    }
    .user1{
      margin-left: 30px;
      img{
        width: 33px;
        height: 33px;
      }
    }
    .btm{
      img{
        width: 15px;
        height: 10px;
        margin-left: 10px;
      }
    }
    span{
      display: inline-block;
      cursor: pointer;
      color: #528CE8;
      width: 120px;
      text-align: left;
      margin-left: 20px;
    };
    .welcome{
      display: inline-block;
      width: 100px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #528CE8;
      color: #fff;
      border-radius: 20px;
      letter-spacing: 2px;
    }
  }
}
.login{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index:99;
  background: RGBA(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  .logInContent{
    width: 350px;
    height: 380px;
    background-color: RGBA(255, 255, 255, 1);
    border-radius: 20px;
    .enroll{
      width: 100%;
      height: 100%;
      position: relative;
      .logo{
        width: 197px;
        height: 125px;
        position: absolute;
        top: -80px;
        left: 50%;
        transform: translate(-50%);
        img{
          width: 100%;
          height: 100%;
        }
      }
      .close{
        width: 15px;
        height: 16px;
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .title{
        width: 300px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: #333333;
        padding-top: 40px;
        font-weight: bold;
        border-bottom: 1px solid #EEECEC;
        margin: auto;
        position: relative;
        .line{
            display: inline-block;
            width: 25px;
            height: 2px;
            background: #528CE8;
            border-radius: 2px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
        }
      }
      .content{
        width: 100%;
        height: 200px;
        margin-top: 10px;
        .login_num{
          width: 100%;
          display: flex;
          justify-content: center;
          .num{
            width: 300px;
          }
        }
        .login-verifyCode{
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 10px;
          .loginCode{
            width: 300px;
          }
        }
        .onlogin{
          width: 100%;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          .onlogin-text{
            width: 300px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #528CE8;
            // box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.2);
            border-radius: 6px;
            cursor: pointer;
            color: #fff;
          }
          
        }
        .register-link{
          width: 100%;
          height: 30px;
          line-height: 30px;
          position: relative;
          span{
            position: absolute;
            right: 30px;
            cursor: pointer;
            color:#999999;
          }
        }
      }
      .Privacy{
        width: 100%;
        height: 130px;
        display: flex;
        justify-content: center;
        .text{
          width: 300px;
          text-align: justify;
          color: #666666;
          font-size: 14px;
          span{
            color:#528CE8;
            cursor: pointer;
            
          }
        }
      }
    }
    .register{
      width: 100%;
      height: 100%;
      position: relative;
      .logo{
        width: 197px;
        height: 125px;
        position: absolute;
        top: -80px;
        left: 50%;
        transform: translate(-50%);
        img{
          width: 100%;
          height: 100%;
        }
      }
      .close{
        width: 15px;
        height: 16px;
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .title{
        width: 300px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: #333333;
        padding-top: 40px;
        font-weight: bold;
        border-bottom: 1px solid #EEECEC;
        margin: auto;
        position: relative;
        .line{
            display: inline-block;
            width: 25px;
            height: 2px;
            background: #528CE8;
            border-radius: 2px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
        }
      }
      .content{
        width: 100%;
        height: 200px;
        margin-top: 10px;
        .login_num{
          width: 100%;
          display: flex;
          justify-content: center;
          .num{
            width: 300px;
          }
        }
        .login-verifyCode{
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 10px;
          .loginCode{
            width: 300px;
          }
        }
        .onlogin{
          width: 100%;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          .onlogin-text{
            width: 300px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #528CE8;
            // box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.2);
            border-radius: 6px;
            cursor: pointer;
            color: #fff;
          }
          
        }
        .register-link{
          width: 100%;
          height: 30px;
          line-height: 30px;
          position: relative;
          span{
            position: absolute;
            right: 30px;
            cursor: pointer;
            color:#999999;
          }
        }
      }
      .Privacy{
        width: 100%;
        height: 130px;
        display: flex;
        justify-content: center;
        font-size: 14px;
        .text{
          width: 300px;
          text-align: justify;
          color: #666666;
          span{
            color:#528CE8;
            cursor: pointer;
            
          }
        }
      }
    }
  }
}
.SlideVerify{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index:100;
  background: RGBA(103, 109, 120, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  .content-box{
    width: 280px;
    height: 260px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 10px;
    .header1{
        width: 240px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #666666;
        .close{
            img{
                width: 15px;
                height: 16px;
            }
        }
    }
    /deep/ .slide-verify-slider{
        margin-top: 5px !important;
    }
  }
  .tips{
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%,-50%);
    color: red;
    z-index: 999;
  }
}
.bottom{
  width: 100%;
  height: 120px;
  background: #000000;
  color: #E6E8F4;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
 .footer-link{
  font-size: 12px;
  color: #E6E8F4;
  margin-top: 10px;
  cursor: pointer;
  a{
    color: #E6E8F4;
    font-size: 12px;
  }
    
 }
 .inline-info{
    color: #BABABA;
    font-size: 12px;
    height: 25px;
    display: flex;
    align-items: center;
    a{
      color: #BABABA;
      font-size: 12px;
      display: block;
      height: 20px;
      display: flex;
      align-items: center;
      img{
        width: 20px;
        height: 20px;
      }
    }
 }
}
.myCenter{
  width: 110px;
  height: 90px;
  background: #fff;
  position: fixed;
  right: 50px;
  top: 60px;
  z-index: 99;
  border-radius: 10px;
  background: url(@/assets/img/pull.png) no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  color: #fff;
  .center{
    width: 110px;
    height: 40px;
    text-align: center;
    line-height:40px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    img{
      width: 12px;
      height: 15px;
    }
  }
  .logout{
    width: 110px;
    height: 40px;
    text-align: center;
    line-height:40px;
    img{
      width: 16px;
      height: 12px;
    }
  }
}
img[src=""],img:not([src]){
    opacity:0;
}
/deep/.el-input-group__append{
    color: #333333 !important;
}
</style>
