import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/nav/HomePage.vue'
import HomeView from "../views/HomeView.vue"
import Navigation from "../views/Navigation.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/Navigation',
  },
  {
    path:"/about",
    name:"about",
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path:"/myCenter",
    name:"myCenter",
    component: () => import('../views/components/myCenter.vue')
  },
  {
    path:"/Navigation",
    name:"Navigation",
    component: () => import(/* webpackChunkName: "about" */ '../views/Navigation.vue'),
  },
  {
    path: '/HomeView',
    name: 'HomeView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
    children:[
      {
        path:"/HomeView/HomePage",
        name:"HomePage",
        component: () => import('../views/nav/HomePage.vue')
      },
      {
        path:"/HomeView/WorldView",
        name:"WorldView",
        component: () => import('../views/nav/WorldView.vue')
      },
      {
        path:"/HomeView/Feature",
        name:"Feature",
        component: () => import('../views/nav/Feature.vue')
      },
      {
        path:"/HomeView/NewsView",
        name:"NewsView",
        component: () => import('../views/nav/NewsView.vue')
      },
      {
        path:"/HomeView/JobberView",
        name:"JobberView",
        component: () => import('../views/nav/Jobber.vue')
      },
      {
        path:"/HomeView/OurselvesView",
        name:"OurselvesView",
        component: () => import('../views/nav/Ourselves.vue')
      },
      {
        path:"/HomeView/UserAgreement",
        name:"UserAgreement",
        component: () => import('../views/components/UserAgreement.vue')
      },
      {
        path:"/HomeView/PrivacyPolicy",
        name:"PrivacyPolicy",
        component: () => import('../views/components/PrivacyPolicy.vue')
      },
      {
        path:"/HomeView/newsDetail",
        name:"newsDetail",
        component: () => import('../views/components/newsDetail.vue')
      },
    ],
  },
  //H5移动端
  {
    path: '/Mobile',
    name: 'Mobile',
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile/MobildHomeView.vue'),
    children:[
      {
        path:"/Mobile/MobileHomePage",
        name:"MobileHomePage",
        component: () => import('../views/mobile/MobileHomePage.vue')
      },
      {
        path:'/Mobile/MobileWorld',
        name:"MobileWorld",
        component: () => import('../views/mobile/MobileWorld.vue')
      },
      {
        path:'/Mobile/MobileFeatrue',
        name:"MobileFeatrue",
        component: () => import('../views/mobile/MobileFeatrue.vue')
      },
      {
        path:'/Mobile/MobileNews',
        name:"MobileNews",
        component: () => import('../views/mobile/MobileNews.vue')
      },
      {
        path:'/Mobile/MobileJobber',
        name:"MobileJobber",
        component: () => import('../views/mobile/MobileJobber.vue')
      },
      {
        path:"/Mobile/PersonalCenter",
        name:"PersonalCenter",
        component: () => import("../views/mobile/PersonalCenter.vue")
      },
      {
        path:"/Mobile/mobilePoicly",
        name:"mobilePoicly",
        component:() => import("../views/components/mobilePoicly.vue")
      },
      {
        path:"/Mobile/mobileUser",
        name:"mobileUser",
        component:() => import("../views/components/mobileUser.vue")
      },
      {
        path:"/Mobile/mobileNewDetail",
        name:"mobileNewDetail",
        component:() => import("../views/components/mobileNewDetail.vue")
      }
    ]
  }
  
]
const router = new VueRouter({
  routes,
})

export default router
