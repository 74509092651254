var NetworkManager = {
     //正常使用只修改isTest，把isTest注释就是调试，不注释true为测试环境，false为线上环境，每次上线必看
    isTest: false,
    url1:function () {
        if(this.isTest == undefined){
            //测试环境
            return "https://appapitest.eyeartsworld.com/";
        }
        else if(this.isTest){
            //测试环境
            return "https://appapitest.eyeartsworld.com/";
        }
        else{
            //正式环境
            return "https://appapi.eyeartsworld.com/";

        }
    },
    url2:function () {
        return  "http://172.17.0.14:18001/";
    },
    url3:function(){
        if(this.isTest == undefined){
            //测试环境
            return "https://operationapitest.eyeartsworld.com/"
        }else if(this.isTest){
            //测试环境
            return "https://operationapitest.eyeartsworld.com/"
        }else{
            //正式环境
            return "https://operationapi.eyeartsworld.com/"
        }
    }

}
const host1=NetworkManager.url1()

const host2=NetworkManager.url2()

const host3 = NetworkManager.url3()
export { NetworkManager ,host1,host2,host3 }