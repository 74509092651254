import { POST, GET, GET2, POST2, POST3 } from './service'
import { host1, host2,host3 } from './host'


export let server = { 
    //获取验证码
    async getWebsiteCode(paramObj){
        return await POST3(host1,'phoneCode/getWebsiteCode',paramObj)
    },
    //登录
    async phoneLogin(paramObj){
        return await POST3(host1,'userLogin/phoneLogin',paramObj)
    },
    //web退出登录
    async websiteLoginOut(paramObj){
        return await POST2(host1,'userLogin/websiteLoginOut',paramObj)
    },
    //web账号信息回显
    async informationEcho(paramObj){
        return await POST2(host1,'userInfor/informationEcho',paramObj);
    },
    //web头像回显
    async avatarEcho(paramObj){
        return await POST2(host1,'userInfor/avatarEcho',paramObj)
    },
    //web修改头像与昵称
    async modifyAvatarNickname(paramObj){
        return await POST2(host1,'userInfor/modifyAvatarNickname',paramObj)
    },
    //web安全校验验证码
    async webSecureSendMessage(paramObj){
        return await POST3(host1,'phoneCode/webSecureSendMessage',paramObj)
    },
    //web绑定验证码
    async webBindingSendMessage(paramObj){
        return await POST3(host1,'phoneCode/webBindingSendMessage',paramObj)
    },
    //web安全校验
    async securityCheck(paramObj){
        return await POST2(host1,'userInfor/securityCheck',paramObj)
    },
    //web更换绑定
    async replaceBinding(paramObj){
        return await POST2(host1,'userInfor/replaceBinding',paramObj)
    },
    async protocolEcho(paramObj){
        return await POST2(host1,'bbs/bbsTopicApp/protocolEcho',paramObj)
    },
    // 首屏视频数据
    async getFirstScreenVideo(paramObj){
        return await POST2(host3,'spider/officialWebsite/getFirstScreenVideo',paramObj)
    },
    //获取下载链接
    async getDownloadLink(paramObj){
        return await POST2(host3,'spider/officialWebsite/getDownloadLink',paramObj)
    },
    //新闻公告数据
    async newsNoticeInformation(paramObj){
        return await POST(host3,'spider/officialWebsite/newsNoticeInformation',paramObj)
    },
    //新闻公告banner数据
    async newsNoticeBannerInformation(paramObj){
        return await POST(host3,'spider/officialWebsite/newsNoticeBannerInformation',paramObj)
    },
    //世界入口 官网 数据回显
    async worldEntranceInformation(paramObj){
        return await POST2(host3,'spider/officialWebsite/worldEntranceInformation',paramObj)
    },
    //特殊玩法官网 数据回显
    async playingMethodInformation(paramObj){
        return await POST2(host3,'spider/officialWebsite/playingMethodInformation',paramObj)
    },
   }